.timeline-education
{
    position: relative;
    max-width: 100%;
}

.timeline-education::after
{
    content:'';
    position: absolute;
    width: 3px;
    height: 100%;
    background: white;
    bottom: 0;
    left:50%;
    margin-left: -3px;
}

@media screen and (max-width: 900px)
{
    .timeline-education::after
    {
        left: 4vw;
    }
}