

.timeline
{
    position: relative;
    max-width: 100%;
}

.date
{
    position: absolute;
    top: 1vh;
    left: 6.5vh;
    height: 35px;
    border-radius: 20px;
    color: #fff;
    place-items:  center;
    font-size: 14px;
    z-index:1;

}
.container
{
    padding: 1vw 3.2vw;
    position: relative;
    width: 50%;
    z-index: 1;
}

.text-box
{
    top: 0.5vh;
    padding: 1vh 1vw;
    background:  white;
    position: relative;
    border-radius: 2vh;
    border-style: solid;
    border-width: 0.25vh;
    border-color: #6aa4e6;     
}
.text-box-education
{
    top: 0.5vh;
    padding: 1vh 1vw;
    background:  white;
    position: relative;
    border-radius: 2vh;
    border-style: solid;
    border-width: 0.25vh;
    border-color: white;     
}
.text-box.notexpanded
{
    max-height: 85px;
    transition: max-height ease-out 0.3s;
}

.text-box.expanded
{ 
    max-height: 700px;
    transition: max-height ease-in 0.3s;
}
.text-box-education.notexpanded
{
    max-height: 85px;
    transition: max-height ease-out 0.3s;
}

.text-box-education.expanded
{ 
    max-height: 500px;
    transition: max-height ease-in 0.3s;
}
.left-container
{
    left:0;
}

.right-container
{
    left:50%;
}

.container img
{
    position: absolute;
    width: 4.5vw;
    border-radius: 50%;
    background-color: white;
    right: -4.75%;
    top: 0.5vh;
    z-index: 5;
    box-shadow: 5px 5px 10px;

}

.container img:hover
{
    cursor: pointer;
    background-color: white;
    transition: 0.8s;
    scale: 1.2;
}

.right-container img
{
    left: -4.75%;
    box-shadow: 5px 5px 10px;
}

.right-container img:hover
{
    cursor: pointer;
}

.timeline::after
{
   
    content:'';
    position: absolute;
    width: 3px;
    height: 100%;
    background: #6aa4e6;
    bottom: 0;
    left:50%;
    margin-left: -3px;

}

.left-container-arrow
{
    height: 0;
    width: 0;
    position: absolute;
    top: 4vh;
    z-index: 1;
    border-top: 0.8vw solid transparent;
    border-bottom: 0.8vw solid transparent;
    border-left: 0.8vw solid #6aa4e6;
    right: 2.5vw;
}

.right-container-arrow
{
    height: 0;
    width: 0;
    position: absolute;
    top: 4vh;
    z-index: 1;
    border-top: 0.8vw solid transparent;
    border-bottom: 0.8vw solid transparent;
    border-right: 0.8vw solid #6aa4e6;
    left: 2.5vw;
}

.left-container-arrow-education
{
    height: 0;
    width: 0;
    position: absolute;
    top: 4vh;
    z-index: 1;
    border-top: 0.8vw solid transparent;
    border-bottom: 0.8vw solid transparent;
    border-left: 0.8vw solid #ffffff;
    right: 2.5vw;
}

.right-container-arrow-education
{
    height: 0;
    width: 0;
    position: absolute;
    top: 4vh;
    z-index: 1;
    border-top: 0.8vw solid transparent;
    border-bottom: 0.8vw solid transparent;
    border-right: 0.8vw solid #ffffff;
    left: 2.5vw;
}


@media screen and (max-width: 1200px) 
{
    .date
    {
        top: -0.5vh;
        left: 3.5vw;
    }

    .text-box.notexpanded
    {
        max-height: 95px;
    }
    
    .text-box.expanded
    {
        max-height: 1000px;
    } 

    .text-box
    {
        top: 0vh;
    }

    .text-box-education
    {
        top: 0vh;
    }

    .container img
    {
        top: 2vh;
    }

    .left-container-arrow
    {
        top: 3.25vh;
    }

    .right-container-arrow
    {
        top: 3.25vh;
    }

    .left-container-arrow-education
    {
        top: 3.25vh;
    }

    .right-container-arrow-education
    {
        top: 3.25vh;
    }
}

@media screen and (max-width: 900px)
{
    .date
    {
        top: -4px;
        left: 50px;
    }

    .text-box
    {
        top: -1vh;
    }

    .text-box-education
    {
        top: -1vh;
    }

    .text-box.notexpanded
    {
        max-height: 13.2vw;
    }
    
    .text-box.expanded
    {
        max-height: 1000px;
    }

    .text-box-education.notexpanded
    {
        max-height: 16.5vw;
    }
    
    .text-box-education.expanded
    {
        max-height: 1000px;
    }

    .timeline::after
    {
        left: 4vw;
    }

    .container
    {
        width: 100%;
        padding-top: 3vh;
        padding-bottom: 2.5vh;
        padding-left: 11vw;
        padding-right: 0vw
    }

    .container img
    {
        position: absolute;
        width: 10vw;
        top: 6vw;
        left: -1.8vw;
        z-index: 289;
        box-shadow: 2px 1px 1px;
    }

.container img:hover
{
    cursor: pointer;
}

    .right-container 
    {
        left: 0;
    }


    .left-container-arrow-education, .right-container-arrow-education
    {  
        border-top: 3vw solid transparent;
        border-bottom: 3vw solid transparent;
        border-right: 3vw solid white;
        border-left: 0;
        top: 4vh;
        left: 8.25vw;
    }
    .left-container-arrow, .right-container-arrow
    {  
        border-top: 3vw solid transparent;
        border-bottom: 3vw solid transparent;
        border-right: 3vw solid #6aa4e6;
        border-left: 0;
        top: 4vh;
        left: 8.25vw;
    }
}