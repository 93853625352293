.NavButton
{
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 0.8vw;
    text-underline-offset: 0.8vh;
}



